import styled from "styled-components";
import { red, darkRed } from "../../styles/colors";
import { Button as _Button } from "../Button";

export const Bar = styled.div`
  display: flex;
  justify-content: center;
  background-image: linear-gradient(${red} 300px, ${darkRed} 100px);
  padding-bottom: 130px;
  box-shadow: inset 0 -3px 10px 5px rgba(0, 0, 0, 0.14);

  @media (min-width: 850px) {
    background-image: linear-gradient(${red} 220px, ${darkRed} 100px);
  }

  @media (min-width: 1024px) {
    background-image: linear-gradient(${red} 140px, ${darkRed} 100px);
  }
`;

export const Content = styled.div`
  flex: 1;
  max-width: 940px;
  display: grid;
  grid-template-columns: 1fr;
  padding: 40px 10px;
`;

export const Heading = styled.h1`
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 30px;
  letter-spacing: 1.2px;
`;
export const Form = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
`;

export const Button = styled(_Button).attrs({ palette: "inverted" })`
  margin-top: 8px;
`;
export const Footer = styled.div`
  background: black;
`;
