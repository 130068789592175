import React from "react";
import { graphql, PageProps } from "gatsby";
import { Layout } from "../components/Layout";
import { Breadcrumbs } from "../components/Breadcrumbs";
import { Section } from "../components/Section";
import { Search } from "../components/Search";
import { SectionHeading } from "../components/SectionHeading";
import { VehicleLargeCardList } from "../components/VehicleLargeCardList";
import { VehicleCard } from "../components/VehicleCard";
import { formatMileage, formatPrice } from "../utils";

interface VehiclesPageProps extends PageProps {
  data: {
    allWordpressPage: any;
    allWordpressWpVehicle: any;
    allWordpressWpService: any;
  };
  pageContext: {
    id: string;
    slug: string;
    title: string;
  };
}

const Vehicles: React.FC<VehiclesPageProps> = ({
  data,
  pageContext,
  location,
}) => {
  const params = new URLSearchParams(location.search);
  const filters = {
    manufacturer: params.get("manufacturer"),
    model: params.get("model"),
    minPrice: params.get("min-price"),
    maxPrice: params.get("max-price"),
  };

  const filteredVehicles = data.allWordpressWpVehicle.edges.filter(
    ({ node: { acf } }) => {
      if (
        filters.manufacturer &&
        filters.manufacturer !== "any" &&
        filters.manufacturer !==
          acf.manufacturer.split("+").join("-").toLowerCase()
      ) {
        return false;
      }

      if (
        filters.model &&
        filters.model !== "any" &&
        filters.model !== acf.model.split("+").join("-").toLowerCase()
      ) {
        return false;
      }

      if (
        filters.minPrice &&
        filters.minPrice !== "any" &&
        Number(filters.minPrice) > Number(acf.price)
      ) {
        return false;
      }

      if (
        filters.maxPrice &&
        filters.maxPrice !== "any" &&
        Number(filters.maxPrice) < Number(acf.price)
      ) {
        return false;
      }

      return true;
    }
  );

  const getSummary = ({
    summary_point_1,
    summary_point_2,
    summary_point_3,
  }) => {
    let summary = [];

    if (summary_point_1 !== null) {
      summary = [...summary, summary_point_1];
    }

    if (summary_point_2 !== null) {
      summary = [...summary, summary_point_2];
    }

    if (summary_point_3 !== null) {
      summary = [...summary, summary_point_3];
    }

    return summary;
  };

  const getPrice = ({ price }) => (price > 0 ? formatPrice(price) : "N/A");

  const getDescription = ({ transmission, engine, paint, mileage }) => {
    let description = [];

    if (transmission !== null) {
      description = [...description, transmission];
    }

    if (engine !== null) {
      description = [...description, engine];
    }

    if (paint !== null) {
      description = [...description, paint];
    }

    if (mileage !== null) {
      description = [...description, formatMileage(mileage)];
    }

    return description.join(" | ");
  };

  return (
    <Layout>
      <Breadcrumbs title={pageContext.title} />
      <Search filters={filters} />
      <Section shouldOverlap>
        <SectionHeading hasBackground>
          <span dangerouslySetInnerHTML={{ __html: pageContext.title }} />
        </SectionHeading>
        <VehicleLargeCardList>
          {filteredVehicles.map(({ node: { id, title, slug, acf } }) => (
            <VehicleCard
              key={id}
              image={acf.gallery && acf.gallery[0] && acf.gallery[0].source_url}
              title={title}
              description={getDescription(acf)}
              price={getPrice(acf)}
              summary={getSummary(acf)}
              href={`/vehicles/${slug}`}
              isLarge
            />
          ))}
        </VehicleLargeCardList>
      </Section>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allWordpressWpVehicle(
      sort: { fields: date, order: DESC }
      filter: { acf: { sold: { ne: true } } }
    ) {
      edges {
        node {
          id
          title
          slug
          acf {
            gallery {
              source_url
            }
            engine
            manufacturer
            mileage
            model
            paint
            price
            transmission
            year
            summary_point_1
            summary_point_2
            summary_point_3
          }
        }
      }
    }
  }
`;

export default Vehicles;
