import React from "react";
import { Bar, Content } from "./styles";
import { SearchForm } from "../SearchForm";

interface SearchType {
  filters?: any;
}

export const Search: React.FC<SearchType> = ({ filters }) => (
  <Bar>
    <Content>
      <SearchForm filters={filters} isInverted />
    </Content>
  </Bar>
);
